import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./adasa.png"
								alt="Adasa"
								className="work-image"
							/>
							<div className="work-title">Adasa</div>
							<div className="work-subtitle">
								Data Analyst
							</div>
							<div className="work-duration">Jan/2017 - Feb/2022</div>
						</div>

						<div className="work">
							<img
								src="./logo-gdf.jpeg"
								alt="Secretaria de Economia do Distrito Federal"
								className="work-image"
							/>
							<div className="work-title">Secretaria de Economia do DF</div>
							<div className="work-subtitle">
								PHP developer
							</div>
							<div className="work-duration">Feb/2022 - Feb/2023</div>
						</div>

						<div className="work">
							<img
								src="./singular.jpeg"
								alt="Singular"
								className="work-image"
							/>
							<div className="work-title">Singular</div>
							<div className="work-subtitle">
								NoCode Analyst
							</div>
							<div className="work-duration">Feb/2023 - Jul/2023</div>
						</div>

						<div className="work">
							<img
								src="./payever.png"
								alt="Payever GmbH"
								className="work-image"
							/>
							<div className="work-title">Payever GmbH</div>
							<div className="work-subtitle">
								Automation Process Engineer
							</div>
							<div className="work-duration">Jul/2023 - Mar/2024</div>
						</div>

						<div className="work">
							<img
								src="./singular.jpeg"
								alt="Singular"
								className="work-image"
							/>
							<div className="work-title">Singular</div>
							<div className="work-subtitle">
								NoCode Analyst
							</div>
							<div className="work-duration">Mar/2024 - Present</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
