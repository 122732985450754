const INFO = {
	main: {
		title: "Caio Queiroz - Automation Engineer",
		name: "Caio Q.",
		email: "caio.queirozz@outlook.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/caiopsq",
		linkedin: "https://www.linkedin.com/in/caio-queiroz-619702158/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Backend developer, focused on Python and other automation tecnologies.",
		description:
			"I'm a backend developer with some expertise in Python, PHP and SQL. I have experience building webscraping applications, APIs, data analysis and some web applications using different frameworks. I'm always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "Hi! I'm Caio, I live in Brazil where I work as a developer and work for a better world.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects have affected the life of many people, changing their life for a better condition. If you're interested in any of the projects I've created and worked with, please feel free to contact me. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedbacks.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/caiopsq",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/caiopsq",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com/caiopsq",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/caiopsq",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/caiopsq",
		},
	],
};

export default INFO;
