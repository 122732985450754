import React from "react";
import { Link } from "react-router-dom";
import ToggleSwitch from "./toggle.jsx"

import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;

	return (
		<React.Fragment>
			<div className="header-container">
				<div className="nav-container">
					<nav className="navbar">
						<div className="nav-background">
							<ul className="nav-list">
								<li
									className={
										active === "home"
											? "nav-item active"
											: "nav-item"
									}
								>
									<Link to="/">Home</Link>
								</li>
								<li
									className={
										active === "about"
											? "nav-item active"
											: "nav-item"
									}
								>
									<Link to="/about">About</Link>
								</li>
								<li
									className={
										active === "projects"
											? "nav-item active"
											: "nav-item"
									}
								>
									<Link to="/projects">Projects</Link>
								</li>
								<li
									className={
										active === "articles"
											? "nav-item active"
											: "nav-item"
									}
								>
									<Link to="/articles">Articles</Link>
								</li>
								<li
									className={
										active === "contact"
											? "nav-item active"
											: "nav-item"
									}
								>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className="switch-container">
					<div className="switch">
						<div className="switch-background">
							<div className="language-switch">
								<ToggleSwitch/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
